var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('NewsItemHeader',{attrs:{"item":_vm.item}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":"","color":"transparent"}},[_c('v-card-title',{staticClass:"pa-0 pt-1"},[_c('router-link',{style:(_vm.reportLinkStyleObject),attrs:{"to":{
                            name: 'CommunityList',
                            params: {
                                category: _vm.item.category,
                                id: _vm.item.id,
                            },
                        }}},[_c('span',{staticClass:"text-h5 primary--text text--lighten-1 font-weight-thin text-wrap px-6"},[_vm._v(_vm._s(_vm.item.heading))])])],1),_c('v-card-text',{staticClass:"pa-0 pt-3"},[_c('div',{staticClass:"text-wrap",class:{ 'grey--text': _vm.$vuetify.dark }},[_c('router-link',{style:(_vm.reportLinkStyleObject),attrs:{"to":{
                                name: 'CommunityList',
                                params: {
                                    category: _vm.item.category,
                                    id: _vm.item.id,
                                },
                            }}},[(_vm.summaryImage)?_c('v-img',{attrs:{"src":_vm.summaryImage,"aspect-ratio":"3.6"}}):_vm._e(),(_vm.summaryText)?_c('div',{staticClass:"px-6",staticStyle:{"white-space":"pre-line"},domProps:{"innerHTML":_vm._s(_vm.summaryText)}}):_vm._e(),(!_vm.summaryImage)?_c('div',{staticClass:"px-6"},[_vm._v(_vm._s(_vm.$htmlSnippet(_vm.textContent, 320)))]):_vm._e()],1)],1),(false)?_vm._l((_vm.item.attachments),function(attachment,j){return _c('div',{key:j},[_c('v-icon',[_vm._v("attachment")]),_vm._v(" "),_c('a',{staticClass:"attachment-link text-wrap",attrs:{"href":attachment.attachment.url,"target":"_blank"}},[_vm._v(_vm._s(attachment.attachment.name)+" | "+_vm._s(_vm.$humaniseFileSize(attachment.attachment.size))+" ")])],1)}):_vm._e()],2)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }