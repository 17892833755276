<template>
    <v-col cols="12" class="pt-2 pb-0">
        <div class="clearfix">
            <div class="float-left px-6">
                {{ itemDate }}<span v-if="countryNames">{{ countryNames }}</span
                >
            </div>
        </div>
    </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import { dateTypes, parseISO, formatDate } from '@/utils/dateFns';

export default {
    name: 'NewsItemHeader',
    props: {
        item: Object,
    },
    i18n: {
        messages: {
            en: require('@/locales/Dashboard/en.json'),
            de: require('@/locales/Dashboard/de.json'),
        },
    },
    computed: {
        ...mapGetters({
            findCountryByCode: 'countries/findByCountryCode',
        }),
        itemDate() {
            if (this.item.date.substring(0, 4) === '1920') {
                return this.$i18n.t('community.groups.date.unknownDate');
            }
            return formatDate(parseISO(this.item.date), dateTypes.fullDate);
        },
        countryNames() {
            let strCountries = '';
            this.item.countries.forEach(x => {
                const country = this.findCountryByCode(x);
                strCountries += country ? ', ' + country.countryName : '';
            });
            return strCountries;
        },
    },
};
</script>
