<template>
    <v-container v-if="item" fluid class="pa-0">
        <v-row>
            <NewsItemHeader
                :item="item"
            />
            <v-col cols="12">
                <v-card flat color="transparent">
                    <v-card-title class="pa-0 pt-1">
                        <router-link
                            :to="{
                                name: 'CommunityList',
                                params: {
                                    category: item.category,
                                    id: item.id,
                                },
                            }"
                            :style="reportLinkStyleObject"
                        >
                            <span
                                class="text-h5 primary--text text--lighten-1 font-weight-thin text-wrap px-6"
                                >{{ item.heading }}</span
                            >
                        </router-link>
                    </v-card-title>
                    <v-card-text class="pa-0 pt-3">
                        <div
                            class="text-wrap"
                            :class="{ 'grey--text': $vuetify.dark }"
                        >
                            <router-link
                                :style="reportLinkStyleObject"
                                :to="{
                                    name: 'CommunityList',
                                    params: {
                                        category: item.category,
                                        id: item.id,
                                    },
                                }"
                            >
                                <v-img v-if="summaryImage" :src="summaryImage" aspect-ratio="3.6"></v-img>
                                <div v-if="summaryText" class="px-6" style="white-space:pre-line;" v-html="summaryText" />
                                <div v-if="!summaryImage" class="px-6">{{ $htmlSnippet(textContent, 320) }}</div>
                            </router-link>
                        </div>
                        <template v-if="false">
                            <div
                                v-for="(attachment, j) in item.attachments"
                                :key="j"
                            >
                                <v-icon>attachment</v-icon>&nbsp;<a
                                    class="attachment-link text-wrap"
                                    :href="attachment.attachment.url"
                                    target="_blank"
                                    >{{ attachment.attachment.name }} | 
                                    {{ $humaniseFileSize(attachment.attachment.size) }}
                                </a>
                            </div>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import NewsItemHeader from './NewsItemHeader.vue';

export default {
    props: {
        item: Object,
        i: Number,
        itemsLength: Number,
    },
    i18n: {
        messages: {
            en: require('@/locales/Dashboard/en.json'),
            de: require('@/locales/Dashboard/de.json'),
        },
    },
    components: {
        NewsItemHeader,
    },
    data() {
        return {};
    },
    computed: {
        arrSummary () {
            return this.item.summary.split('\n');
        },
        summaryImage () {
            if (this.arrSummary.length && this.arrSummary[0].match(/\.(jpeg|jpg|gif|png)$/) !== null) {
                return this.arrSummary[0];
            }
            return null;
        },
        summaryText () {
            if (!this.arrSummary || this.arrSummary.length <= 1) {
                return null;
            }
            let arrReturn = [...this.arrSummary];
            arrReturn.shift();

            return arrReturn.join('\n');
        },
        textContent () {
            if (this.summaryImage) {
                return null;
            }
            if (this.item.summary && this.item.summary.length > 8) {
                return this.item.summary;
            }
            return this.item.content;
        },
        reportLinkStyleObject () {
            const styleObject = {
                color: this.$vuetify.dark ? '#fff' : 'rgba(0,0,0,.87)',
                textDecoration: 'none',
            };
            return styleObject;
        },
    }
};
</script>
